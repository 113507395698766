import React, { useState, useEffect, useCallback } from "react";
import { getAdmins } from "../../apis/users";
import Edit from "./Edit";
import Password from "./Password";
import Add from "./Add";
import authentication from "../../utils/authentication";
import { toast } from "react-toastify";

const Admins = () => {
  const [data, setData] = useState([]);
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getAdmins();
      if (apiResponse?.data?.data?.length > 0) {
        setData([...apiResponse?.data?.data]);
      } else {
        setData([]);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error.response.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      }
    }
  }, [data]);

  useEffect(() => {
    fetch();
  }, []);
  return (
    <div className="section">
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <Add fetch={fetch} />
          </div>
          <table>
            <thead>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.role}</td>
                  <td>
                    <div className="flex">
                      <Edit fetch={fetch} data={item} />
                      <Password fetch={fetch} data={item} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Admins;
