import React, { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { createAdmin } from "../../apis/users";
import authentication from "../../utils/authentication";

const Add = ({ fetch }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
  });

  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const submitHandler = useCallback(async () => {
    try {
      if (
        !formData.name ||
        !formData.email ||
        !formData.role ||
        !formData.password
      ) {
        toast.error("Name and Email is required");
        return;
      }
      await createAdmin(formData);
      fetch();
      setOpen(false);
      setFormData({
        name: "",
        email: "",
        role: "",
        password: "",
      });
      toast.success("Admin added successfully");
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error.response.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, [formData]);
  return (
    <div className="modal-container">
      <div className="add-modal">
        <button className="primary" onClick={openHandler}>
          Add
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>Add Admin</h2>
            <button type="button" className="danger" onClick={openHandler}>
              Close
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <h4>Profile</h4>
              <div className="input-wrapper">
                <label>
                  Name
                  <span className="required">*</span>
                </label>
                <input
                  name="name"
                  value={formData.name}
                  onChange={changeHandler}
                />
              </div>
              <div className="input-wrapper">
                <label>
                  Email
                  <span className="required">*</span>
                </label>
                <input
                  name="email"
                  value={formData.email}
                  onChange={changeHandler}
                />
              </div>
              <div className="input-wrapper">
                <label>
                  Password
                  <span className="required">*</span>
                </label>
                <input
                  name="password"
                  value={formData.password}
                  onChange={changeHandler}
                />
              </div>
              <div className="input-wrapper">
                <label>
                  Role
                  <span className="required">*</span>
                </label>
                <select
                  name="role"
                  value={formData.role}
                  onChange={changeHandler}
                >
                  <option
                    value=""
                    disabled
                    style={{ display: "none" }}
                  ></option>
                  <option value="admin">Admin</option>
                  <option value="rm">RM</option>
                </select>
              </div>
            </div>
          </div>
          <div className="footer">
            <button onClick={submitHandler} type="button" className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
