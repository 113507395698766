import React, { useState, useCallback, useEffect } from "react";
import { getDegradingPercentage } from "../../apis/preferences";
import Edit from "./Edit";
import { toast } from "react-toastify";
import authentication from "../../utils/authentication";

const DegradingPercentage = () => {
  const [preferences, setPreferences] = useState([]);
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getDegradingPercentage();
      setPreferences([...apiResponse?.data?.data]);
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error.response.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="section">
      <div className="wrapper">
        {preferences?.length > 0 && (
          <div className="table-wrapper">
            <table>
              <thead>
                <th>#</th>
                <th>Preference Name</th>
                <th>Degrading Percentage</th>
                <th>Action</th>
              </thead>
              <tbody>
                {preferences?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.degradingPercentage}</td>
                    <td>
                      <Edit data={item} fetch={fetch} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DegradingPercentage;
