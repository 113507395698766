import React from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import routes from "../sidebar/routes";
import SeeMatches from "../../pages/SeeMatches/SeeMatches";

const Routes = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <Switch>
      <Route
        exact
        path="*"
        element={
          localStorage.getItem("token") ? (
            <Navigate to="/users" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      {routes
        .filter((route) => !route.isAuthenticated)
        .map((route) => (
          <Route exact path={route.path} element={route.component} />
        ))}

      {user?.role === "rm"
        ? routes
            .filter((route) => route.isAuthenticated && route.isForRM)
            .map((route) => (
              <Route
                exact
                path={route.path}
                element={
                  <div className="container">
                    <Sidebar />
                    <div className="body-wrapper">{route.component}</div>
                  </div>
                }
              />
            ))
        : routes
            .filter((route) => route.isAuthenticated)
            .map((route) => (
              <Route
                exact
                path={route.path}
                element={
                  <div className="container">
                    <Sidebar />
                    <div className="body-wrapper">{route.component}</div>
                  </div>
                }
              />
            ))}
      <Route
        exact
        path="/matches/:id"
        element={
          <div className="container">
            <Sidebar />
            <div className="body-wrapper">
              <SeeMatches />
            </div>
          </div>
        }
      />
    </Switch>
  );
};

export default Routes;
