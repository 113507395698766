import React, { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { updatePassword } from "../../apis/users";

const Password = ({ fetch, data }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
  });

  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const submitHandler = useCallback(async () => {
    try {
      if (!formData.name || !formData.email) {
        toast.error("Name and Email is required");
        return;
      }
      await updatePassword(data._id, formData);
      fetch();
      setOpen(false);
      toast.success("Scorecard updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  }, [formData]);
  return (
    <div className="modal-container">
      <div className="add-modal">
        <button className="info" onClick={openHandler}>
          Change Password
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>Change {data.name} Password</h2>
            <button type="button" className="danger" onClick={openHandler}>
              Close
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <h4>Profile</h4>
              <div className="input-wrapper">
                <label>
                  Password
                  <span className="required">*</span>
                </label>
                <input
                  name="password"
                  value={formData.password}
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>
          <div className="footer">
            <button onClick={submitHandler} type="button" className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;
