import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import ImageUploadComponent from "./asset";
import "../subscription/subscription.css";

const SubscriptionAdd = ({
  plan,
  open,
  setOpen,
  openHandler,
  selectedid,
  onupdate,
  fetchSubscriptions,
}) => {
  const [data, setData] = useState({
    plan: "",
    planTag: "",
    slogan: "",
    price: 0,
    discount: 0,
    isActive: true,
    isFreePlan: false,
    isLive: false,
    duration: 0,
    image: null,
    features: {
      curatedProfiles: 0,
      monthlyCuratedProfiles: 0,
      videoSnippets: false,
      mysyncOrOursync: [],
      subscriptionPauseDays: 0,
      assistedPreferenceChanges: false,
      dedicatedSoulSyncer: false,
      accessToFullFeatures: false,
      hideNamesVisible: false,
      benifits: [],
    },
  });

  useEffect(() => {
    if (plan) {
      fetchPlanData();
    }
  }, [plan, selectedid]);

  const fetchPlanData = async () => {
    try {
      const response = await axios(`subscription/${selectedid}`, "get", {});
      setData(response.data);
      onupdate();
      toast.success("Subscription updated successfully");
    } catch (error) {
      console.error("Error fetching plan data", error);
    }
  };

  const handleChange = (e, field, nestedField) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    setData((prevData) => {
      let isFreePlan = prevData.isFreePlan;
      if (field === "plan" && value.toLowerCase() === "free") {
        isFreePlan = true;
      } else if (field === "plan") {
        isFreePlan = false;
      } else if (field === "image") {
        return {
          ...prevData,
          [field]: e.target.files[0],
        };
      }

      if (nestedField) {
        if (nestedField === "mysyncOrOursync" && e.target.type === "checkbox") {
          return {
            ...prevData,
            isFreePlan,
            [field]: {
              ...prevData[field],
              [nestedField]: e.target.checked
                ? [...prevData[field][nestedField], e.target.value]
                : prevData[field][nestedField].filter(
                    (item) => item !== e.target.value
                  ),
            },
          };
        } else if (nestedField.startsWith("benifit")) {
          const benifitIndex = parseInt(nestedField.slice(-1)) - 1;
          const updatedBenifits = [...prevData.features.benifits];
          updatedBenifits[benifitIndex] = value;

          return {
            ...prevData,
            isFreePlan,
            features: {
              ...prevData.features,
              benifits: updatedBenifits,
              monthlyCuratedProfiles:
                prevData.features.curatedProfiles / (prevData.duration / 30),
            },
          };
        } else {
          return {
            ...prevData,
            isFreePlan,
            [field]: {
              ...prevData[field],
              [nestedField]: value,
              monthlyCuratedProfiles:
                field === "features" && nestedField === "curatedProfiles"
                  ? value / (prevData.duration / 30)
                  : prevData.features.monthlyCuratedProfiles,
            },
          };
        }
      } else {
        return {
          ...prevData,
          isFreePlan,
          [field]: value,
        };
      }
    });
  };

  const handlefileChange = (e) => {
    handleChange(e, "image");
  };

  const deletehandler = async () => {
    if (selectedid) {
      try {
        const response = await axios(
          `subscription/delete/${selectedid}`,
          "delete",
          data
        );
        toast.success("Subscription deleted successfully");
        setOpen(false);
      } catch (error) {
        toast.error("Error deletion subscription");
      }
    } else {
      toast.error("please select to delete");
    }
  };

  const submitHandler = async () => {
    if (data.plan.length > 10) {
      toast.error("Subscription name should be in 10 letters");
      return;
    }
    if (Number.isInteger(data.monthlyCuratedProfiles)) {
      toast.error("Monthlty Curated Profile Must be in Integer");
      return;
    }

    if (data.planTag.length > 10 || data.planTag == "") {
      toast.error("Subscription tag should be in 10 letters");
      return;
    }

    if (
      data.features.benifits[0]?.length > 40 ||
      data.features.benifits[1]?.length > 40 ||
      data.features.benifits[2]?.length > 40 ||
      data.features.benifits[3]?.length > 40 ||
      data.features.benifits[4]?.length > 40 ||
      data.features.benifits[5]?.length > 40
    ) {
      toast.error("benifit should be in 40 characters or less");
      return;
    }
    if (selectedid) {
      try {
        if (
          data?.image != null &&
          document.querySelector(".uploadAsset").files[0] != undefined
        ) {
          const AssetUrl = await uploadImg(
            document.querySelector(".uploadAsset").files[0]
          );
          data.image = AssetUrl;
        }

        const response = await axios(
          `subscription/edit/${selectedid}`,
          "put",
          data
        );
        //  onupdate()

        toast.success("Subscription updated successfully");
        setOpen(false);
        fetchSubscriptions();
      } catch (error) {
        console.error("Error updated subscription", error);
        toast.error(error);
      }
    } else {
      try {
        if (data.image != null) {
          const AssetUrl = await uploadImg(data.image);
          data.image = AssetUrl;
        }

        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        const response = await axios("subscription/create", "post", data, {
          headers,
        });
        toast.success("Subscription added successfully");

        setOpen(false);
        fetchSubscriptions();
        // fetch();
      } catch (error) {
        toast.error(error.response.data.error.message);
      }
    }
  };

  const uploadImg = async (img) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    const formdata = new FormData();
    formdata.append("file", img);

    try {
      const res = await axios("user/uploadImage", "put", formdata, headers);
      return res.data.url;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal-opener">
        <button className="primary" onClick={openHandler}>
          Add Subscription
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2> {selectedid ? `Update` : `Add`} Subscription</h2>
            <button type="button" className="danger" onClick={openHandler}>
              Close
            </button>
          </div>
          <div className="content">
            <div className="input-wrapper">
              <div className="form-section">
                <h4>Icon</h4>
                <ImageUploadComponent
                  value={data?.image}
                  onChange={handlefileChange}
                />
              </div>
            </div>
            <div className="form-section">
              <h4> Sync Options</h4>
              <div className="checkboxes">
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    value="MySync"
                    checked={data.features.mysyncOrOursync.includes("MySync")}
                    onChange={(e) =>
                      handleChange(e, "features", "mysyncOrOursync")
                    }
                  />
                  <label>My Sync</label>
                </div>
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    value="OurSync"
                    checked={data.features.mysyncOrOursync.includes("OurSync")}
                    onChange={(e) =>
                      handleChange(e, "features", "mysyncOrOursync")
                    }
                  />
                  <label>Our Sync</label>
                </div>
              </div>
            </div>
            <div className="form-section">
              <h4> Active & Live Status</h4>
              <div className="checkboxes">
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    checked={data.isActive}
                    onChange={(e) => handleChange(e, "isActive")}
                  />
                  <label>Is Active</label>
                </div>
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    checked={data.isLive}
                    onChange={(e) => handleChange(e, "isLive")}
                  />
                  <label>Is Live</label>
                </div>
              </div>
            </div>
            <div className="form-section">
              <h4>Information</h4>

              <div className="input-wrapper">
                <label>
                  Name <span className="required">*</span>
                </label>
                <input
                  value={data.plan}
                  onChange={(e) => handleChange(e, "plan")}
                />
              </div>

              <div className="input-wrapper">
                <label>
                  Slogan <span className="required">*</span>
                </label>
                <input
                  value={data.slogan}
                  onChange={(e) => handleChange(e, "slogan")}
                />
              </div>
              <div className="input-wrapper">
                <label>
                  Tag <span className="required">*</span>
                </label>
                <input
                  value={data.planTag}
                  onChange={(e) => handleChange(e, "planTag")}
                />
              </div>

              <div className="flexed">
                <div className="input-wrapper">
                  <label>
                    Price <span className="required">*</span>
                  </label>
                  <input
                    value={data.price}
                    onChange={(e) => handleChange(e, "price")}
                  />
                </div>
                <div className="input-wrapper">
                  <label>
                    Discount <span className="required">*</span>
                  </label>
                  <input
                    value={data.discount}
                    onChange={(e) => handleChange(e, "discount")}
                  />
                </div>
                <div className="input-wrapper">
                  <label>
                    Duration <span className="required">*</span>
                  </label>

                  <select
                    style={{ width: "200px" }}
                    value={data.duration}
                    onChange={(e) => handleChange(e, "duration")}
                  >
                    <option value="">select duration</option>
                    <option value="90">3 Months</option>
                    <option value="180">6 Months</option>
                    <option value="365">1 year</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-section">
              <h4>Features</h4>

              <div className="flexed">
                <div className="input-wrapper">
                  <label>
                    Curated Profiles <span className="required">*</span>
                  </label>
                  <select
                    value={data.features.curatedProfiles}
                    onChange={(e) =>
                      handleChange(e, "features", "curatedProfiles")
                    }
                  >
                    {[...Array(20).keys()].map((value) => (
                      <option key={value * 3 + 3} value={value * 3 + 3}>
                        {value * 3 + 3}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-wrapper">
                  <label>
                    Monthly Curated Profiles <span className="required">*</span>
                  </label>
                  <input
                    value={data.features.monthlyCuratedProfiles}
                    onChange={(e) =>
                      handleChange(e, "features", "monthlyCuratedProfiles")
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <label>
                    Subscription Pause Days <span className="required">*</span>
                  </label>
                  <input
                    value={data.features.subscriptionPauseDays}
                    onChange={(e) =>
                      handleChange(e, "features", "subscriptionPauseDays")
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form-section">
              <h4>Benifits</h4>

              {[1, 2, 3, 4, 5, 6].map((index) => (
                <div className="input-wrapper" key={index}>
                  <label>Benifit {index}</label>
                  <input
                    value={data.features.benifits[index - 1]}
                    onChange={(e) =>
                      handleChange(e, "features", `benifit${index}`)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="footer">
            <button onClick={submitHandler} type="button" className="primary">
              {selectedid ? `Update` : `Submit`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAdd;
