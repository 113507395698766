import React, { useState, useEffect, useCallback } from "react";
import "./subscription.css";

const ImageUploadComponent = ({ value, onChange, isVideo }) => {
  const [image, setImage] = useState(value);

  console.log(value);
  useEffect(() => {
    if (isVideo) {
      setImage(value);
    }
  }, [value, isVideo]);

  const changeHandler = useCallback(
    (e) => {
      if (e.target?.files[0]) {
        setImage(URL.createObjectURL(e.target?.files[0]));
        onChange(e, "image");
      }
    },
    [isVideo]
  );
  return (
    <div className="input-wrapper-image">
      {isVideo ? (
        <div className="video">
          {(image || value) && (
            <video id="video">
              <source src={image || value} />
            </video>
          )}
        </div>
      ) : (
        <div className="image">
          {(image || value) && <img src={image || value} />}
        </div>
      )}
      <label htmlFor="image">
        {isVideo ? "Upload Video (1080 * 1350 pixels)" : "Upload Image"}
      </label>
      <input
        style={{ display: "none" }}
        className="uploadAsset"
        id="image"
        type="file"
        accept={isVideo ? "video/*" : "image/*"}
        onChange={(e) => changeHandler(e)}
      />
    </div>
  );
};

export default ImageUploadComponent;
