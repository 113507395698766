import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import routes from "./routes";
import { GiHamburgerMenu } from "react-icons/gi";
import authentication from "../../utils/authentication";
import { BiLogOut } from "react-icons/bi";
import "./sidebar.css";

const Sidebar = () => {
  const menuRef = useRef(null);
  const sidebarRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", (event) => {
      const sidebarElement = document.getElementById("sidebar");
      if (sidebarElement) {
        if (menuRef?.current?.contains(event.target)) {
          sidebarElement.classList = "sidebar active";
        } else {
          if (!sidebarRef?.current?.contains(event.target)) {
            sidebarElement.classList = "sidebar";
          }
        }
      }
    });
    return () => document.removeEventListener("click", () => {});
  }, [menuRef, sidebarRef]);
  const pathname = useLocation().pathname;
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <div className="sidebar" id="sidebar" ref={sidebarRef}>
      <nav>
        <div className="nav-wrapper">
          <button type="button" className="dark" ref={menuRef}>
            <GiHamburgerMenu size={16} />
          </button>
        </div>
      </nav>
      <img className="logo" src="/images/logo.svg" alt="" />
      {user?.role === "rm"
        ? routes
            .filter((route) => route.link && route.isForRM)
            .map((route, routeIndex) => (
              <div
                key={`route${routeIndex}`}
                className={
                  route.path === pathname ? "navlink active" : "navlink"
                }
              >
                {route.icon}
                {route.link}
              </div>
            ))
        : routes
            .filter((route) => route.link)
            .map((route, routeIndex) => (
              <div
                key={`route${routeIndex}`}
                className={
                  route.path === pathname ? "navlink active" : "navlink"
                }
              >
                {route.icon}
                {route.link}
              </div>
            ))}
      <div className="navlink">
        <BiLogOut size={24} />
        <button
          className="transparent"
          onClick={(e) => {
            e.preventDefault();
            authentication.removeAuthentication();
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
