import axios from "../utils/axios";

const endpoint = "preferences";

export const add = (data) => axios(endpoint, "POST", data);

export const get = () => axios(endpoint, "GET");

export const getDegradingPercentage = () =>
  axios(`${endpoint}/degrading-percentage`, "GET");

export const updateDegradingPercentage = (id, data) =>
  axios(`${endpoint}/degrading-percentage/${id}`, "PATCH", data);
