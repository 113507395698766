import React, { useState, useCallback, useEffect } from "react";
import { get, getRMAdmins, updateUserRM, updateUser } from "../../apis/users";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Add from "../../components/users/Add";
import "./Users.css";
import { toast } from "react-toastify";
import authentication from "../../utils/authentication";

const Users = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [viewData, setViewData] = useState(null);
  const [requestType, setRequestType] = useState("All");

  const page = useLocation().search.split("=")[1];

  const fetchRMAdmins = useCallback(async () => {
    try {
      const apiResponse = await getRMAdmins();
      if (apiResponse?.data?.data?.length > 0) {
        setAdmins([...apiResponse?.data?.data]);
      } else {
        setAdmins([]);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      }
    }
  }, []);

  const fetchUsers = useCallback(
    async (num) => {
      if (requestType) {
        try {
          const apiResponse = await get(
            num || page,
            searchUser?.trim(),
            requestType
          );
          setData([...apiResponse?.data?.data?.users]);
          setPageInfo({ ...apiResponse?.data?.data?.pageInfo });
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    },
    [page, searchUser, requestType]
  );

  const handleSearchUser = (e) => {
    const value = e.target.value;
    setSearchUser(value);
  };

  const fetch = useCallback(
    (num) => {
      fetchUsers(num || page);
      fetchRMAdmins();
    },
    [requestType, page]
  );

  useEffect(() => {
    if (page) {
      fetch(1);
    } else {
      navigate("/users?page=1");
    }
  }, [page, requestType]);

  const assignRM = useCallback(async (id, assignedRM) => {
    try {
      await updateUserRM(id, assignedRM);
      fetchUsers();
      toast.success("Relationship Manager assigned successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  }, []);

  const activateDeactivateUser = useCallback(async (id, isActive) => {
    try {
      await updateUser(id, { isActive });
      fetchUsers();
      toast.success(
        isActive
          ? "User activated assigned successfully"
          : "User deactivated assigned successfully"
      );
    } catch (error) {
      toast.error("Something went wrong");
    }
  }, []);

  const openHandler = useCallback((item) => {
    if (item?._id) {
      setViewData(item);
    } else {
      setViewData(null);
    }
  }, []);

  const searchUserHandler = useCallback(() => {
    setLoading(true);
    fetchUsers(1);
  }, [searchUser]);
  const getExpiryDate = (startDate, startTime, pausedDays, duration) => {
    const date = new Date(
      new Date(
        `${startDate.year}-${startDate.month}-${startDate.day} ${startTime.hours}:${startTime.minutes}:${startTime.seconds}`
      ).getTime() +
        (duration + pausedDays) * 1000 * 24 * 60 * 60
    );
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const viewAllHandler = useCallback((value) => {
    setRequestType(value);
    setLoading(true);
  }, []);

  return (
    <div className="section">
      {loading && (
        <div className="loader-wrapper">
          <span class="loader" />
        </div>
      )}
      <Add
        open={viewData?._id ? true : false}
        openHandler={openHandler}
        data={viewData}
        fetch={fetchUsers}
      />
      <div className="wrapper">
        <div className="table-header justify-start">
          {user?.role === "rm" && (
            <div className="input-wrapper">
              <select
                value={requestType}
                onChange={(e) => viewAllHandler(e.target.value)}
              >
                <option value="All">View all</option>
                <option value="Me">Only me</option>
              </select>
            </div>
          )}
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Search User"
              value={searchUser}
              onChange={(e) => handleSearchUser(e)}
              className="search-input"
            />
          </div>
          <button className="primary" onClick={() => searchUserHandler()}>
            Search
          </button>
        </div>
        {data?.length > 0 && (
          <div className="table-wrapper">
            <table>
              <thead>
                <th>#</th>
                <th>Customer ID</th>
                <th>Customer Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>City</th>
                <th>Subscription Status</th>
                <th>Expiry Date</th>
                <th>See Matches</th>
                <th>Actions</th>
                <th>Active/Inactive</th>
                <th>Relationship Manager</th>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={item._id}>
                    <td>{(Number(page) - 1) * 20 + index + 1}</td>
                    <td>{item.customerId}</td>
                    <td>{item.name}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.email}</td>
                    <td>{item.currentResidingCity}</td>
                    <td>
                      {item?.userPlan?.plan?.substring(0, 1)?.toUpperCase() +
                        item?.userPlan?.plan?.substring(1)?.toLowerCase()}
                    </td>
                    <td>
                      {item?.userPlan && !item?.userPlan?.isFreePlan
                        ? getExpiryDate(
                            item?.userPlan?.startDate,
                            item?.userPlan?.startTime,
                            item?.userPlan?.pausedDays,
                            item?.userPlan?.duration
                          )
                        : ""}
                    </td>
                    <td>
                      <Link
                        to={`/matches/${item._id}?gender=${
                          item.gender === "Male" ? "Female" : "Male"
                        }`}
                      >
                        See Matches
                      </Link>
                    </td>
                    <td>
                      <button
                        className="info"
                        onClick={() => openHandler(item)}
                      >
                        View
                      </button>
                    </td>
                    <td>
                      <button
                        className={!item.isActive ? "success" : "danger"}
                        onClick={() =>
                          activateDeactivateUser(item._id, !item.isActive)
                        }
                      >
                        {item.isActive ? "Deactivate" : "Activate"}
                      </button>
                    </td>
                    <td>
                      {user?.role === "rm" ? (
                        item?.admin?.name
                      ) : (
                        <div className="input-wrapper">
                          <select
                            value={item?.admin?._id || ""}
                            onChange={(event) =>
                              assignRM(item._id, event.target.value)
                            }
                          >
                            <option
                              key={item._id + index}
                              value=""
                              disabled
                              style={{ display: "none" }}
                            ></option>
                            {admins?.map((admin) => (
                              <option
                                key={item.Add_d + admin._id}
                                value={admin._id}
                              >
                                {admin.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="table-footer">
              {!pageInfo?.prevPage ? (
                <button className="secondary disabled">Prev</button>
              ) : (
                <Link to={`/users?page=${pageInfo.prevPage}`}>
                  <button className="secondary">Prev</button>
                </Link>
              )}
              {!pageInfo?.nextPage ? (
                <button className="secondary disabled">Next</button>
              ) : (
                <Link to={`/users?page=${pageInfo.nextPage}`}>
                  <button className="secondary">Next</button>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
