import React, { useEffect } from "react";
import "../subscription/subscription.css";

function SubscriptionTbl({ data, openHandler }) {
  useEffect(() => {
    renderTableData();
    // console.log(data)
  }, [data]);

  const profileTextMapping = {
    MySync: "Profile Based on- My Sync",
    OurSync: "Profile Based on- Our Sync",
    myandoursync: "Profile Based on- My Sync and Our Sync",
  };

  const renderTableHeaders = () => {
    const headers = [
      "Asset",
      "Subscription Name",
      "Subscription Tag",
      "Slogan",
      "Curated Profiles",
      "Sync",
      "Paused Days",
      "Duration",
      "Pause Days",
      "Benifits",
      "Discount",
      "Price",
      "Active",
      "Edit",
    ];

    return headers.map((header, index) => (
      <td key={index} style={{ color: "#f1b429" }}>
        {header}
      </td>
    ));
  };

  const renderTableData = () => {
    return data.map((subscription) => (
      <tr key={subscription._id}>
        <td>
          <img className="assetimg" src={subscription.image} />
        </td>
        <td>{subscription.plan}</td>
        <td>{subscription.planTag}</td>
        <td>{subscription.slogan}</td>
        <td>
          Curated Profile : {subscription.features.curatedProfiles} (
          {subscription.features.curatedProfiles / 3} / month)
        </td>
        <td>
          {subscription.features.mysyncOrOursync.length === 2
            ? profileTextMapping["myandoursync"]
            : subscription.features.mysyncOrOursync.includes("MySync")
            ? profileTextMapping["MySync"]
            : profileTextMapping["OurSync"]}
        </td>
        <td> paused days -{subscription.features.subscriptionPauseDays}</td>
        <td>{subscription.duration}</td>
        <td>{subscription.features.subscriptionPauseDays}</td>
        <td> {subscription.features?.benifits?.join(", ")}</td>
        <td>{subscription.discount}</td>
        <td>{subscription.price}</td>
        <td>{subscription.isActive ? "✅" : "❌"}</td>

        <td onClick={() => handleEdit(subscription.plan, subscription._id)}>
          <button className="primary">Edit</button>
        </td>
      </tr>
    ));
  };

  const handleEdit = (plan, id) => {
    openHandler(plan, id);
  };

  return (
    <div className="table-wrapper">
      <table>
        <thead>
          <tr>{renderTableHeaders()}</tr>
        </thead>
        <tbody>{renderTableData()}</tbody>
      </table>
    </div>
  );
}

export default SubscriptionTbl;
