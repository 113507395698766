import React, { useState, useEffect, useCallback } from "react";
import { getScorecards } from "../../apis/users";
import Add from "./Add";
import { toast } from "react-toastify";
import authentication from "../../utils/authentication";

const Scorecards = () => {
  const [data, setData] = useState([]);
  const [timer, setTimer] = useState(localStorage.getItem("timer") || "");
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getScorecards();
      if (apiResponse?.data?.data?.length > 0) {
        setData([...apiResponse?.data?.data]);
      } else {
        setData([]);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error.response.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, [data]);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="section">
      <div className="wrapper">
        <div className="table-wrapper">
          <table>
            <thead>
              <th>#</th>
              <th>Name</th>
              <th>Score</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.score}</td>
                  <td>
                    {!timer && (
                      <Add fetch={fetch} data={item} setTimer={setTimer} />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Scorecards;
