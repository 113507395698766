import axios from "../utils/axios";

const usersEndpoint = "users";
const matchSyncEndpoint = "matchsync";

export const add = (data) =>
  axios(usersEndpoint, "POST", data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const get = (page, search, requestType) =>
  axios(
    `${usersEndpoint}?page=${page}&search=${search}&requestType=${requestType}`,
    "GET",
    {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  );

export const getScorecards = () =>
  axios(`${usersEndpoint}/scorecards`, "GET", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
export const getAdmins = () =>
  axios(`admins`, "GET", null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
export const getRMAdmins = () =>
  axios(`admins/rm`, "GET", null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const updateAdmin = (id, data) =>
  axios(`admins/${id}`, "PUT", data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const updateUser = (id, data) =>
  axios(`${usersEndpoint}/update/${id}`, "PUT", data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const createAdmin = (data) =>
  axios(`admins`, "POST", data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const updatePassword = (id, data) =>
  axios(`admins/${id}`, "PATCH", data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
export const updateScorecard = (name, data) =>
  axios(`${usersEndpoint}/scorecards/${name}`, "PUT", data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const getAll = () =>
  axios(`${usersEndpoint}/all`, "GET", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const getReferals = (page) =>
  axios(`${usersEndpoint}/referals?page=${page}`, "GET", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
export const updateReferal = (id, assignedRM) =>
  axios(`${usersEndpoint}/referals/${id}/${assignedRM}`, "PATCH", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
export const updateUserRM = (id, assignedRM) =>
  axios(`${usersEndpoint}/${id}/${assignedRM}`, "PATCH", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const getMatchesStatistics = (id, gender) =>
  axios(`${usersEndpoint}/matches/${id}?gender=${gender}`, "GET", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const getMatchUsers = (id, users, requestType, page) =>
  axios(
    `${usersEndpoint}/matches/${id}?page=${page}&requestType=${requestType}`,
    "POST",
    { users },
    {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  );

export const searchUsers = (user) =>
  axios(`${usersEndpoint}/search?userName=${user || ""}`, "GET", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const markMetExploring = (id, userId) =>
  axios(`${usersEndpoint}/match/${id}/user/${userId}/exploring`, "PUT", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const markMetNotInterested = (id, userId) =>
  axios(`${usersEndpoint}/match/${id}/user/${userId}/notinterested`, "PUT", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const matchMaking = (id, userId) =>
  axios(`${usersEndpoint}/match/${id}/user/${userId ? userId : "all"}`, "GET", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const pushProfile = (id, userId) =>
  axios(`${usersEndpoint}/match/${id}/user/${userId}`, "PUT", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const importUsers = (data) =>
  axios(`${usersEndpoint}/bulk-import`, "POST", data);

/**
 * ! Match sync api endpoints
 * @returns
 */

export const getAllMatchSyncBrackets = () =>
  axios(`${matchSyncEndpoint}/`, "GET", {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const addMatchSyncBracket = (data) =>
  axios(`${matchSyncEndpoint}`, "POST", data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const editMatchSyncBracket = (id, data) =>
  axios(`${matchSyncEndpoint}/${id}`, "PUT", data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
