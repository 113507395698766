import { React, useState, useEffect, useCallback } from "react";
import { TbUserStar } from "react-icons/tb";
import { FaStar, FaFontAwesome, FaFreeCodeCamp } from "react-icons/fa";

import SubscriptionAdd from "./subscriptionAdd";
import "./subscription.css";
import axios from "../../utils/axios";
import SubscriptionTbl from "./SubscriptionTbl";

function Subscription() {
  const [data, setdata] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedform, setselectedform] = useState("");
  const [selectedid, setselectedid] = useState("");
  const [open, setOpen] = useState(false);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios("subscription/", "get", {});
      setSubscriptions(response.data);
      setLoading(false);
      setdata(response.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSubscriptions();
  }, [selectedid]);

  const GoldIcon = () => <FaStar color="gold" size={32} />;
  const SilverIcon = () => <FaStar color="silver" size={32} />;
  const FreeIcon = () => <FaFontAwesome color="green" size={32} />;

  const openHandler = useCallback(
    (plan, id) => {
      setselectedform(plan);
      setselectedid(id);
      setOpen(!open);
    },
    [open]
  );

  return (
    <div className="section">
      <div className="wrapper">
        <SubscriptionAdd
          plan={selectedform}
          setOpen={setOpen}
          openHandler={openHandler}
          open={open}
          selectedid={selectedid}
          // onupdate={fetchSubscriptions}
          fetchSubscriptions={fetchSubscriptions}
        />
        <SubscriptionTbl data={data} openHandler={openHandler} />
      </div>
    </div>
  );
}

export default Subscription;
