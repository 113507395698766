import React, { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import ImageUploadComponent from "../../pages/subscription/asset";
import { updateUser } from "../../apis/users";
import axios from "../../utils/axios";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Add = ({ fetch, data, open, openHandler }) => {
  const [state, setState] = useState({
    "About Me": [],
    "My Journey": [],
    "My Lifestyle": [],
    "My Family": [],
    "My Future Outlook": [],
    "More About Me": [],
  });

  useEffect(() => {
    if (data?._id) {
      let temp = {
        "About Me": [],
        "My Journey": [],
        "My Lifestyle": [],
        "My Family": [],
        "My Future Outlook": [],
        "More About Me": [],
      };
      if (
        data?.dateOfBirth?.year &&
        data?.dateOfBirth?.month &&
        data?.dateOfBirth?.day
      ) {
        temp["About Me"].push({
          title: "Age",
          value: ageCalculator(
            `${data.dateOfBirth.year}-${data.dateOfBirth.month}-${data.dateOfBirth.date} 00:00:00`
          ),
        });
        temp["About Me"].push({
          title: "Date Of Birth",
          value: `${data.dateOfBirth.date} ${
            months[data.dateOfBirth.month - 1]
          } ${data.dateOfBirth.year}`,
        });
      }
      if (data?.height) {
        temp["About Me"].push({
          title: "Height",
          value: data.height,
        });
      }
      if (data?.workDetails?.annualEarning) {
        temp["About Me"].push({
          title: "Annual Income",
          value: data?.workDetails?.annualEarning,
        });
      }
      if (data?.maritalStatus) {
        temp["About Me"].push({
          title: "Marital Status",
          value: data.maritalStatus,
        });
      }
      if (data?.currentResidingCity) {
        temp["About Me"].push({
          title: "Current Residing City",
          value: data.currentResidingCity,
        });
      }
      if (data?.nationality) {
        temp["About Me"].push({
          title: "Nationality",
          value: data.nationality,
        });
      }
      if (data?.motherTongue) {
        temp["About Me"].push({
          title: "Mother Tongue",
          value: data.motherTongue,
        });
      }
      if (data?.religion) {
        temp["About Me"].push({
          title: "Religion",
          value: data.religion,
        });
      }
      if (data?.haveKids) {
        temp["About Me"].push({
          title: "Has kids?",
          value: data.haveKids,
        });
      }
      if (data?.secondarySchool?.name) {
        let str = data?.secondarySchool?.name;
        if (data?.secondarySchool?.city && data?.secondarySchool?.year) {
          str += ` (${data?.secondarySchool?.city} - ${data?.secondarySchool?.year})`;
        } else if (data?.secondarySchool?.city) {
          str += ` (${data?.secondarySchool?.city})`;
        } else if (data?.secondarySchool?.year) {
          str += ` (${data?.secondarySchool?.year})`;
        }
        temp["My Journey"].push({
          title: "Secondary School",
          value: str,
        });
      }
      if (data?.graduationDegree?.course) {
        let str = data?.graduationDegree?.course;
        if (
          data?.graduationDegree?.institution &&
          data?.graduationDegree?.yearOfCompletion
        ) {
          str += ` (${data?.graduationDegree?.institution} - ${data?.graduationDegree?.yearOfCompletion})`;
        } else if (data?.graduationDegree?.institution) {
          str += ` (${data?.graduationDegree?.institution})`;
        } else if (data?.graduationDegree?.yearOfCompletion) {
          str += ` (${data?.graduationDegree?.yearOfCompletion})`;
        }
        temp["My Journey"].push({
          title: "Graduation Degree",
          value: str,
        });
      }
      if (data?.postGraduationDegree?.course) {
        let str = data?.postGraduationDegree?.course;
        if (
          data?.postGraduationDegree?.institution &&
          data?.postGraduationDegree?.yearOfCompletion
        ) {
          str += ` (${data?.postGraduationDegree?.institution} - ${data?.postGraduationDegree?.yearOfCompletion})`;
        } else if (data?.postGraduationDegree?.institution) {
          str += ` (${data?.postGraduationDegree?.institution})`;
        } else if (data?.postGraduationDegree?.yearOfCompletion) {
          str += ` (${data?.postGraduationDegree?.yearOfCompletion})`;
        }
        temp["My Journey"].push({
          title: "Post-Graduation Degree",
          value: str,
        });
      }
      if (data?.higherDegrees?.institution) {
        temp["My Journey"].push({
          title: "Higher Degree",
          value: data?.higherDegrees?.institution,
        });
      }
      if (data?.foodOutlook) {
        temp["My Lifestyle"].push({
          title: "Food Outlook",
          value: data.foodOutlook,
        });
      }
      if (data?.fitnessOutlook) {
        temp["My Lifestyle"].push({
          title: "Fitness Outlook",
          value: data.fitnessOutlook,
        });
      }
      if (data?.personality) {
        temp["My Lifestyle"].push({
          title: "Personality",
          value: data.personality,
        });
      }
      if (data?.idealWeekend) {
        temp["My Lifestyle"].push({
          title: "Ideal Weekend",
          value: data.idealWeekend,
        });
      }
      if (data?.smoke) {
        temp["My Lifestyle"].push({
          title: "Smokes",
          value: data.smoke,
        });
      }
      if (data?.drinkAlcohol) {
        temp["My Lifestyle"].push({
          title: "Drink Alcohol",
          value: data.drinkAlcohol,
        });
      }
      if (data?.familyDescription) {
        temp["My Family"].push({
          title: "Family Type",
          value: data.familyDescription,
        });
      }
      if (data?.fatherProfession) {
        temp["My Family"].push({
          title: "Father Profession",
          value: data.fatherProfession,
        });
      }
      if (data?.fatherIndustry) {
        temp["My Family"].push({
          title: "Father Industry",
          value: data.fatherIndustry,
        });
      }
      if (data?.motherProfession) {
        temp["My Family"].push({
          title: "Mother Profession",
          value: data.motherProfession,
        });
      }
      if (data?.motherIndustry) {
        temp["My Family"].push({
          title: "Mother Industry",
          value: data.motherIndustry,
        });
      }
      if (data?.siblings?.length > 0) {
        temp["My Family"].push({
          title: "Siblings",
          value: data.siblings.join(", "),
        });
      }
      if (data?.annualFamilyIncome) {
        temp["My Family"].push({
          title: "Annual Family Income",
          value: data.annualFamilyIncome,
        });
      }
      if (data?.wantKids) {
        temp["My Future Outlook"].push({
          title: "Preference for kids",
          value: data.wantKids,
        });
      }
      if (data?.livingArrangement) {
        temp["My Future Outlook"].push({
          title: "Preference for Living Arrangement",
          value: data.livingArrangement,
        });
      }
      if (data?.roleModel) {
        temp["More About Me"].push({
          title: "Who is your role model?",
          value: data.roleModel,
        });
      }
      if (data?.describe) {
        temp["More About Me"].push({
          title: "Describe yourself in 3 words",
          value: data.describe,
        });
      }
      if (data?.proudOf) {
        temp["More About Me"].push({
          title: "What are you most proud of?",
          value: data.proudOf,
        });
      }

      setState({ ...temp });
    }
  }, [data]);

  const fileChangeHandler = useCallback(
    async (e) => {
      if (e?.target?.files[0]) {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };
        const form = new FormData();
        form.append("file", e?.target?.files[0]);

        try {
          const res = await axios("users/video", "post", form, headers);

          await updateUser(data._id, { video: res.data.url });
          fetch();
          toast.success("User profile updated successfully");
          openHandler();
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    },
    [data]
  );

  const ageCalculator = (date) => {
    var dob = new Date(date);
    console.log(dob);
    var month_diff = Date.now() - dob.getTime();

    var age_dt = new Date(month_diff);

    var year = age_dt.getUTCFullYear();

    var age = Math.abs(year - 1970);
    return age;
  };

  return (
    <div className="modal-container">
      {open && (
        <div className="modal active">
          <div className="wrapper full">
            <div className="header">
              <h2>{data.name}</h2>
              <button type="button" className="danger" onClick={openHandler}>
                Close
              </button>
            </div>
            <div className="content">
              <div className="form-section">
                <h4>Profile Video</h4>
                <ImageUploadComponent
                  value={data?.video}
                  onChange={fileChangeHandler}
                  isVideo={true}
                />
              </div>
              {Object.keys(state)?.length > 0 && (
                <>
                  {Object.keys(state)
                    .filter((item) => state[item]?.length > 0)
                    ?.map((el) => (
                      <div className="form-section">
                        <h4>{el}</h4>
                        <div className="flexed">
                          {state[el].map((el1, index) => (
                            <div
                              className="input-wrapper"
                              key={`${el1.title}-${index}`}
                            >
                              <label>{el1.title}</label>
                              <span>{el1.value}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </>
              )}
              {/* <div className="form-section">
                <h4>Profile</h4>
                <div className="flexed">
                  {data?._id &&
                    Object.keys(data)
                      .filter(
                        (item) =>
                          [
                            "_id",
                            "customerId",
                            "images",
                            "video",
                            "cover",
                            "admin",
                          ].indexOf(item) === -1
                      )
                      .map((item, index) => {
                        if (typeof data[item] === "string") {
                          return (
                            <div
                              className="input-wrapper"
                              key={`${data._id}-${index}`}
                            >
                              <label>{camelToFlat(item)}</label>
                              <span>{data[item]}</span>
                            </div>
                          );
                        } else {
                          return (
                            <>
                              {Object.keys(data[item]).map((el, idx) => (
                                <div
                                  className="input-wrapper"
                                  key={`${data._id}-${index}-${idx}`}
                                >
                                  <label>
                                    {camelToFlat(el)} ({camelToFlat(item)})
                                  </label>
                                  <span>{data[item][el]}</span>
                                </div>
                              ))}
                            </>
                          );
                        }
                      })}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Add;
