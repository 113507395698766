import React, { useState } from "react";
import "./Tabs.css";
import MatchSyncBrackets from "../../pages/MatchSync Brackets/MatchSyncBrackets";

const Tabs = ({ activeTab, onTabChange }) => {
  return (
    <div>
      <div className="tab-buttons">
        <button
          className={activeTab === "mySync" ? "active" : ""}
          onClick={() => onTabChange("mySync")}
        >
          My Sync
        </button>
        <button
          className={activeTab === "ourSync" ? "active" : ""}
          onClick={() => onTabChange("ourSync")}
        >
          Our Sync
        </button>
      </div>
    </div>
  );
};

export default Tabs;
